/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
import TRADE, { TRADE_CONSTANTS } from 'actionTypes/trade';
import USER, { UNAUTHORIZED } from 'actionTypes/user';
import { getAllContractsData } from 'components/holdings/helper';
import {
  pipe,
  find,
  propEq,
  prop,
  map,
  filter,
  keys,
  findIndex,
  path,
  forEach,
  update,
  merge,
  remove,
  mergeDeepRight,
  uniqWith,
  eqBy,
  sort,
  evolve,
} from 'helpers/ramda';
import {
  getContractType,
  contractsObject,
  isFutures,
  isTurbo,
  isCallPutOptions,
  isOptionCombos,
} from 'helpers/utils';
import i18n from 'i18n/config';
import { ContractType } from 'types/IProducts';

import { PRIVATE_SOCKET_ACTIONS } from './helpers/trade';
// import { updateOrderbook } from 'actions/trade';

// import { initialState as openOrdersState } from './openOrders';
// import tradeState from './initialstate/trade';

// const productTypeV2 = {
//   future: 'vanilla',
//   inverse_future: 'inverse',
// };

const tabList = [
  {
    label: i18n.t('trading:holdingsTabList.openPositions'),
    id: 'open-positions',
    selectValue: 'open-positions',
  },
  {
    label: i18n.t('trading:holdingsTabList.openOrders'),
    id: 'open-orders',
    selectValue: 'open-orders',
  },
  {
    label: i18n.t('trading:holdingsTabList.stopOrders'),
    id: 'stop-orders',
    selectValue: 'stop-orders',
  },
  {
    label: i18n.t('trading:holdingsTabList.balance'),
    id: 'balance',
    selectValue: 'balance',
  },
  {
    label: i18n.t('trading:holdingsTabList.fills'),
    id: 'fills',
    selectValue: 'fills',
  },
  {
    label: i18n.t('trading:holdingsTabList.orderHistory'),
    id: 'orders-history',
    selectValue: 'orders-history',
  },
];

const tabListMobile = [
  {
    label: i18n.t('trading:holdingsTabList.openPositions'),
    id: 'openPositions',
  },
  {
    label: i18n.t('trading:holdingsTabList.openOrders'),
    id: 'openOrders',
  },
  {
    label: i18n.t('trading:holdingsTabList.conditionalOrders'),
    id: 'stopOrders',
  },
  {
    label: i18n.t('trading:holdingsTabList.fills'),
    id: 'fills',
  },
  {
    label: i18n.t('trading:holdingsTabList.orderHistory'),
    id: 'orderHistory',
  },
];

const initialState = {
  desktop: tabList,
  mobile: tabListMobile,
  openPositions: contractsObject(),
  openOrders: contractsObject(),
  openStopOrders: contractsObject(),
  bracketOrders: {},
  selectedContractType: 'all',
  holdingsFilterType: 'dropdown',
  mobileHoldingsContractType: 'all',
  loading: {
    openPositions: true,
    // Below are false as we never know whether we have orders or not
    openOrders: false,
    openStopOrders: false,
  },
  products: {},
};

// Deprecated this function, not required anymore. Test before removing
const updateOpenOrdersLabel = (id, count) =>
  map(tab => {
    if (id.includes(tab.id)) {
      const countLabel = count > 0 ? `[${count}]` : '';
      const label = pipe(find(propEq('id', tab.id)), prop('label'))(tabList);
      return {
        ...tab,
        label: `${label} ${countLabel}`,
      };
    }
    return tab;
  });

const filterOrdersFromApi = orders => {
  const {
    all,
    futures,
    options,
    spreads,
    interest_rate_swaps,
    spot,
    turbo_options,
    options_combos,
  } = contractsObject();
  all.data = orders;
  futures.data = filter(order => isFutures(order.product.contract_type), orders);
  options.data = filter(
    order =>
      isCallPutOptions(order.product.contract_type) ||
      order.product.contract_type === ContractType.MoveOptions,
    orders
  );
  spot.data = filter(order => order.product.contract_type === 'spot', orders);
  interest_rate_swaps.data = filter(
    order => order.product.contract_type === 'interest_rate_swaps',
    orders
  );
  spreads.data = filter(
    order =>
      order.product.contract_type === 'interest_rate_swaps' ||
      order.product.contract_type === 'spreads',
    orders
  );
  turbo_options.data = filter(order => isTurbo(order.product.contract_type), orders);
  options_combos.data = filter(
    order => isOptionCombos(order.product.contract_type),
    orders
  );
  return {
    all,
    futures,
    options,
    spot,
    interest_rate_swaps,
    spreads,
    turbo_options,
  };
};

const holdings = (state = initialState, action) => {
  let id = [];
  let count = null;
  let slug = '';
  let data;
  let evolveState;
  let bracketOrders;

  switch (action.type) {
    case TRADE.POSITIONS.SUCCESS:
      slug = 'openPositions';
      id.push('open-positions');
      const positionsSnapshot = action.result.result;

      const openPositions = contractsObject();

      // Adding product and bracket order data
      // :TODO abstract
      forEach(position => {
        position.bracket_orders = {
          stop_loss_order: {
            limit_price: null,
            stop_price: null,
            trail_amount: null,
          },
          take_profit_order: {
            limit_price: null,
            stop_price: null,
            trail_amount: null,
          },
        };
        // const {
        //   stop_loss_order,
        //   take_profit_order,
        // } = position.bracket_orders;
        // if (!stop_loss_order && !take_profit_order) {
        //   position.bracket_orders = {
        //     stop_loss_order: {
        //       limit_price: null,
        //       stop_price: null,
        //       trail_amount: null,
        //     },
        //     take_profit_order: {
        //       limit_price: null,
        //       stop_price: null,
        //       trail_amount: null,
        //     },
        //   };
        // } else if (!stop_loss_order) {
        //   position.bracket_orders = {
        //     stop_loss_order: {
        //       limit_price: null,
        //       stop_price: null,
        //       trail_amount: null,
        //     },
        //     take_profit_order: { ...take_profit_order },
        //   };
        // } else if (!take_profit_order) {
        //   position.bracket_orders = {
        //     stop_loss_order: { ...stop_loss_order },
        //     take_profit_order: {
        //       limit_price: null,
        //       stop_price: null,
        //       trail_amount: null,
        //     },
        //   };
        // }

        const contractType = getContractType(
          path(['product', 'contract_type'], position)
        );
        openPositions[contractType]?.data.push(position);
      }, positionsSnapshot);

      openPositions.all = getAllContractsData(openPositions);
      data = openPositions;
      count = data[state.selectedContractType].data.length;
      break;

    case TRADE_CONSTANTS.OPEN_POSITIONS:
      slug = 'openPositions';
      id.push('open-positions');
      data = action.result;

      // If the bracket order data was already there, fetch it.
      const contractTypes = keys(data);
      contractTypes.forEach(contract => {
        data[contract].data.forEach(position => {
          if (state.bracketOrders[position.product_id]) {
            const bracketOrderObj = state.bracketOrders[position.product_id];
            if (bracketOrderObj.take_profit_order) {
              position.bracket_orders.take_profit_order =
                bracketOrderObj.take_profit_order;
            }
            if (bracketOrderObj.stop_loss_order) {
              position.bracket_orders.stop_loss_order = bracketOrderObj.stop_loss_order;
            }
          }
        });
      });
      count = data[state.selectedContractType].data.length;
      break;
    case TRADE.OPEN_ORDERS.SUCCESS:
      const orders = action.result.body.result;
      slug = 'openOrders';
      id.push('open-orders');
      const filteredOpenOrder = filterOrdersFromApi(orders);
      count = filteredOpenOrder[state.selectedContractType].data.length;
      return {
        ...state,
        openOrders: filteredOpenOrder,
      };
    case TRADE.OPEN_STOP_ORDERS.SUCCESS: {
      const orders = action.result.body.result;
      slug = 'openStopOrders';
      id.push('stop-orders');
      const filteredOpenStopOrder = filterOrdersFromApi(orders);
      count = filteredOpenStopOrder[state.selectedContractType].data.length;
      return {
        ...state,
        openStopOrders: filteredOpenStopOrder,
      };
    }
    case TRADE_CONSTANTS.OPEN_ORDERS:
      slug = 'openOrders';
      id.push('open-orders');
      return getOrders(action.result, state, id, slug);

    case TRADE_CONSTANTS.OPEN_STOP_ORDERS:
      slug = 'openStopOrders';
      id.push('stop-orders');
      return getOrders(action.result, state, id, slug);
    case UNAUTHORIZED:
    case USER.LOGOUT.SUCCESS:
      id = ['open-positions', 'open-orders', 'stop-orders'];
      count = null;
      // console.log('DEBUG', { action, initialState, state });
      // returning initialState was having some position and orders inside.
      // todo:@suraj-kawale-au2 refactor holdings reducer.
      return {
        desktop: tabList,
        mobile: tabListMobile,
        openPositions: contractsObject(),
        openOrders: contractsObject(),
        openStopOrders: contractsObject(),
        bracketOrders: {},
        selectedContractType: 'all',
        holdingsFilterType: 'dropdown',
        mobileHoldingsContractType: 'all',
        loading: {
          openPositions: true,
          // Below are false as we never know whether we have orders or not
          openOrders: false,
          openStopOrders: false,
        },
      };
    case TRADE_CONSTANTS.CHANGE_CONTRACT_TYPE:
      id = ['open-positions', 'open-orders', 'stop-orders'];
      count = null;
      break;

    case TRADE_CONSTANTS.CHANGE_MOBILE_CONTRACT_TYPE: {
      const contract_type = action.payload;
      return {
        ...state,
        mobileHoldingsContractType: contract_type,
      };
    }

    case TRADE_CONSTANTS.CHANGE_HOLDINGS_CONTRACT_TYPE:
      const contract_type = action.payload;
      return {
        ...state,
        selectedContractType: contract_type,
      };

    case TRADE_CONSTANTS.CHANGE_HOLDINGS_FILTER_TYPE:
      const filterType = action.payload;
      return {
        ...state,
        holdingsFilterType: filterType,
      };

    case TRADE_CONSTANTS.UPDATE_OPEN_ORDERS:
      id = ['open-orders'];
      slug = 'openOrders';
      return updateOrderObj(action.result, state, id, slug);

    case TRADE_CONSTANTS.UPDATE_STOP_ORDERS:
      id = ['stop-orders'];
      slug = 'openStopOrders';
      return updateOrderObj(action.result, state, id, slug);
    case TRADE_CONSTANTS.DELETE_BRACKET_ORDER:
      data = action.result;
      bracketOrders = state.bracketOrders;

      bracketOrders[data.product_id][data.stop_order_type] = initBracketOrderObj();

      return getNewState(state, [data], bracketOrders, 'delete');

    case TRADE_CONSTANTS.UPDATE_POSITIONS:
      id = ['open-positions'];
      slug = 'openPositions';
      return updateOrderObj(action.result, state, id, slug);

    case TRADE_CONSTANTS.UPDATE_BRACKET_ORDER:
      data = action.result;
      bracketOrders = state.bracketOrders;
      if (!bracketOrders[data[0].product_id]) {
        bracketOrders[data[0].product_id] = {};
      }

      data.forEach(
        obj =>
          (bracketOrders[obj.product_id][obj.stop_order_type] = getBracketOrderObj(obj))
      );

      // If we already have positions data, update bracket orders
      return getNewState(state, data, bracketOrders, 'update');
    default:
      return state;
  }

  evolveState = getEvolveState(state, id, count);

  return updateStateObj(slug, state, data, evolveState);
};

const initBracketOrderObj = () => ({
  limit_price: null,
  stop_price: null,
  trail_amount: null,
  stop_trigger_method: null,
});

const getNewState = (state, data, bracketOrders, action) => {
  const contractType = getContractType(data[0].product.contract_type);
  const index = findIndex(propEq('product_id', data[0].product_id))(
    path(['openPositions', contractType, 'data'], state)
  );
  const indexAll = findIndex(propEq('product_id', data[0].product_id))(
    path(['openPositions', 'all', 'data'], state)
  );
  let finalState = {
    ...state,
    bracketOrders,
  };
  if (index !== -1) {
    const positionObj = path(['openPositions', contractType, 'data', index], state);
    data.forEach(
      obj =>
        (positionObj.bracket_orders[obj.stop_order_type] =
          action === 'delete' ? initBracketOrderObj() : getBracketOrderObj(obj))
    );

    const openPositions = state.openPositions[contractType];
    openPositions.data = update(index, positionObj, openPositions.data);

    const allOpenPositions = state.openPositions.all;
    allOpenPositions.data = update(indexAll, positionObj, allOpenPositions.data);
    finalState = merge(finalState, {
      openPositions: {
        ...state.openPositions,
        [contractType]: openPositions,
        all: allOpenPositions,
      },
    });
  }
  return finalState;
};

const getOrders = (data, state, id, slug) => {
  const contractType = getContractType(path([0, 'product', 'contract_type'], data));

  const allOrders = getAllContractsData({
    ...state[slug],
    [contractType]: {
      data,
    },
    all: {
      data: [],
    },
  });

  const newOrders = {
    ...state[slug],
    [contractType]: {
      data,
    },
    all: allOrders,
  };

  const count = getCount(state, contractType, data, allOrders, slug);
  const evolveState = getEvolveState(state, id, count);
  return updateStateObj(slug, state, newOrders, evolveState);
};

const getBracketOrderObj = obj => ({
  limit_price: obj.limit_price,
  stop_price: obj.stop_price,
  trail_amount: obj.trail_amount,
  stop_trigger_method: obj.stop_trigger_method,
  id: obj.id,
});

const resetBracketOrderObj = obj => ({
  limit_price: null,
  stop_price: null,
  trail_amount: null,
  id: obj.id,
});

const updateOrderObj = (data, state, id, slug) => {
  const positionActionType = data.action;
  const contractType = getContractType(data.product?.contract_type);
  let orders = state[slug][contractType].data;
  let allOrders = state[slug].all.data;

  let pindex;
  let indexInAll;

  if (positionActionType !== PRIVATE_SOCKET_ACTIONS.CREATE) {
    pindex = getIndex(orders, data, slug);
    indexInAll = getIndex(allOrders, data, slug);
    if (pindex === -1) {
      return {
        ...state,
      };
    }
  }

  const { take_profit_order, stop_loss_order } =
    state.bracketOrders[data.product.id] || {};
  data.bracket_orders = {};

  data.bracket_orders.take_profit_order = take_profit_order || resetBracketOrderObj({});
  data.bracket_orders.stop_loss_order = stop_loss_order || resetBracketOrderObj({});

  switch (positionActionType) {
    case PRIVATE_SOCKET_ACTIONS.CREATE:
      orders.unshift(data);
      allOrders.unshift(data);
      break;
    case PRIVATE_SOCKET_ACTIONS.UPDATE:
      orders[pindex] = mergeDeepRight(orders[pindex], data);
      allOrders[indexInAll] = mergeDeepRight(allOrders[indexInAll], data);
      break;
    case PRIVATE_SOCKET_ACTIONS.DELETE:
      orders = remove(pindex, 1, orders);
      allOrders = remove(indexInAll, 1, allOrders);
      break;
    default:
      break;
  }

  if (slug === 'openPositions') {
    orders = uniqWith(eqBy(prop('product_id')))(orders);
    allOrders = uniqWith(eqBy(prop('product_id')))(allOrders);
  } else {
    orders = sortOrdersByCreatedAt(orders);
    allOrders = sortOrdersByCreatedAt(allOrders);
  }

  const newOrders = {
    ...state[slug],
    [contractType]: {
      data: orders,
    },
    all: {
      data: allOrders,
    },
  };

  const count = state.selectedContractType === 'all' ? allOrders.length : orders.length;
  const evolveState = getEvolveState(state, id, count);
  return updateStateObj(slug, state, newOrders, evolveState);
};

const sortOrdersByCreatedAt = data =>
  sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
    uniqWith(eqBy(prop('id')))(data)
  );

const getIndex = (dataArray, newObj, slug) => {
  let pindex = -1;
  dataArray.forEach((obj, index) => {
    if (
      (slug === 'openPositions' && obj.product.id === newObj.product_id) ||
      (slug !== 'openPositions' && obj.id === newObj.id)
    ) {
      pindex = index;
    }
  });
  return pindex;
};

const updateStateObj = (slug, state, data, evolveState) => {
  return slug
    ? {
        ...evolveState,
        [slug]: data,
        loading: {
          ...state.loading,
          [slug]: false,
        },
      }
    : { ...evolveState };
};

const getCount = (state, contractType, data, allContracts, slug) => {
  return state.selectedContractType === contractType
    ? data.length
    : state.selectedContractType === 'all'
    ? allContracts.data.length
    : state[slug][state.selectedContractType].data.length;
};

const getEvolveState = (state, id, count) => {
  return evolve(
    {
      desktop: updateOpenOrdersLabel(id, count),
    },
    state
  );
};

export default holdings;
