/* eslint-disable camelcase */
/* eslint-disable consistent-return */
import { combineEpics, ofType } from 'redux-observable';
import { REHYDRATE } from 'redux-persist';
import { empty, EMPTY, from, interval, of } from 'rxjs';
import {
  catchError,
  debounce,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
} from 'rxjs/operators';

import { getOrderLeverage } from 'actions/orderbook';
import { resetAllPagination } from 'actions/pagination';
import {
  // withSelector,
  contractTypeFilter,
  getFills,
  // getL2Orderbook,
  // getRecentTrades,
  getOrdersHistory,
  // getExpiredProductsFail,
  getPositionsFromApi,
  // getOpenOrders,
  // getOpenStopOrders,
  // getPositions,
  getTradePreferences,
  getWithdrawalTradingCredits,
  withHoldingFilters,
  withoutContractType,
} from 'actions/trade';
import {
  getAppliedPromos,
  getKycStatus,
  getReferralData,
  getUserProfile,
  isAuthenticated,
} from 'actions/user';
import { getBalances, getConversionConfig, getInvestBalances } from 'actions/wallet';
import { LoginViaBiometricsActionTypes, LoginViaQrActionTypes } from 'actionTypes/auth';
import { OFFRAMP_ACTION_TYPES } from 'actionTypes/offramp';
import ORDER_BOOK from 'actionTypes/orderbook';
import TRADE, { TRADE_CONSTANTS } from 'actionTypes/trade';
// eslint-disable-next-line import/order
import USER, { USER_CONNECTED } from 'actionTypes/user';

// import { TAB_REACTIVE } from 'actionTypes/other';
import WALLET from 'actionTypes/wallet';
import { pipe } from 'helpers/ramda';
import { isMobileOrTablet, isOptions } from 'helpers/utils';
import {
  selectedProductIdSelector,
  selectedProductSelector,
} from 'selectors/tradeSelectors';

import { getProductByIDHelper } from './alerts';

const getSelectedProductLeverage = () => {
  return (dispatch, getState) => {
    const selectedProduct = selectedProductSelector(getState());
    if (
      selectedProduct &&
      selectedProduct.contract_type &&
      selectedProduct.contract_type !== 'spot'
    ) {
      pipe(getState, selectedProductIdSelector, getOrderLeverage, dispatch)();
    }
  };
};

const postOrderSuccessActions = [
  getSelectedProductLeverage,
  isMobileOrTablet() ? contractTypeFilter(getFills) : withHoldingFilters(getFills),
];

const postOrderCancelActions = [
  getBalances,
  getSelectedProductLeverage,
  getInvestBalances,
];

const postAuthActions = [
  getBalances,
  getInvestBalances,
  getPositionsFromApi,
  getConversionConfig,
  // getPendingDeposits,
  getTradePreferences,
  // getWalletAddress,
  getReferralData,
  getUserProfile,
  getKycStatus,
  getAppliedPromos,
  getWithdrawalTradingCredits,
];

const getTradePageActions = () => {
  const isTradePage = window.location.pathname.includes('/trade/');
  if (isTradePage) {
    return (dispatch, getState) => [
      // withSelector(getL2Orderbook, selectedProductIdSelector)()(
      //   dispatch,
      //   getState
      // ),
      // withSelector(getRecentTrades, selectedProductIdSelector)()(
      //   dispatch,
      //   getState
      // ),
      isMobileOrTablet()
        ? contractTypeFilter(getFills)()(dispatch, getState)
        : withHoldingFilters(getFills)()(dispatch, getState),
      isMobileOrTablet()
        ? contractTypeFilter(getOrdersHistory)()(dispatch, getState)
        : withHoldingFilters(getOrdersHistory)()(dispatch, getState),
    ];
  }
  return () => [];
};

const isTransactionLogPage = () => {
  return window.location.pathname.includes('/transaction_logs/');
};

const getTransactionPageAction = () => {
  if (isTransactionLogPage()) {
    return dispatch => [
      withoutContractType(getFills)(dispatch),
      withoutContractType(getOrdersHistory)(dispatch),
    ];
  }
  return () => [];
};

// TODO: Should I add getTradePreferences, getPendingDeposits, getWalletAddress??
const refreshDataActions = [
  getBalances,
  getPositionsFromApi,
  getInvestBalances,
  getTradePageActions,
  getTransactionPageAction,
  getConversionConfig,
  () => resetAllPagination(),
];

const holdingsCalls = [
  withHoldingFilters(getFills),
  withHoldingFilters(getOrdersHistory),
];

// Delays each and every action in refreshDataActions array
// const refreshDataActions = _refreshDataActions.map(withDelay)

const trade = {
  [ORDER_BOOK.CHANGE_ORDER_LEVERAGE.SUCCESS]: [getBalances],
  [TRADE.WITHDRAWAL_TRADING_CREDITS_FORFEIT.SUCCESS]: [
    getBalances,
    getWithdrawalTradingCredits,
  ],
  [OFFRAMP_ACTION_TYPES.SUBMIT_FIAT_WITHDRAWAL_CREATE.SUCCESS]: [
    getBalances,
    getWithdrawalTradingCredits,
  ],
  [WALLET.CONVERT_CURRENCY.SUCCESS]: [getBalances],
  [TRADE.ORDER.SUCCESS]: postOrderSuccessActions,
  [TRADE.ORDER_CANCEL.SUCCESS]: postOrderCancelActions,
  [USER_CONNECTED]: refreshDataActions,
  [TRADE.WALLET_BALANCE_TOP_UP.SUCCESS]: [getBalances],
  // [TAB_REACTIVE]: refreshDataActions,
};

const authTrade = {
  [LoginViaQrActionTypes.SET_AUTHORIZED_USER]: postAuthActions,
  [LoginViaBiometricsActionTypes.SET_AUTHORIZED_USER]: postAuthActions,
  [USER.LOGIN.SUCCESS]: postAuthActions,
  [USER.LOGINMFA.SUCCESS]: postAuthActions,
  [USER.LOGIN_EMAIL_VERIFICATION.SUCCESS]: postAuthActions,
  [USER.PHONE_OTP_VERIFICATION.SUCCESS]: postAuthActions,
  [REHYDRATE]: postAuthActions,
};

const aTYPES = Object.keys(authTrade);
export const authTradeEpic = (action$, state$) =>
  action$.pipe(
    ofType(...aTYPES),
    filter(() => isAuthenticated(state$.value.user)),
    map(action => {
      if (action.fromSync || action.key === 'optionsChain') {
        // rehydrate running on session storage persist also . added check to prevent multiple api calls
        return null;
      }
      return authTrade[action.type];
    }),
    mergeMap(postActions => {
      if (!postActions) {
        return empty();
      }
      return of(...postActions).pipe(
        map(pAction => pAction()),
        catchError(err => console.error('DEBUG:\n', err))
      );
    })
  );

const TYPES = Object.keys(trade);
export const tradeEpic = (action$, state$) =>
  action$.pipe(
    ofType(...TYPES),
    filter(() => isAuthenticated(state$.value.user)),
    map(action => {
      if (action.fromSync) {
        return null;
      }
      return trade[action.type];
    }),
    mergeMap(postActions => {
      if (!postActions) {
        return empty();
      }
      return of(...postActions).pipe(
        map(pAction => pAction()),
        catchError(err => console.error('DEBUG:\n', err))
      );
    })
  );

const selectedOrderHistory = () => {
  // NOTE: Commented for future reference
  // const filterType = state.holdings.holdingsFilterType;
  // if (filterType === 'selected') {
  //   return [
  //     withSelectedProductFilterType(getFills),
  //     withSelectedProductFilterType(getOrdersHistory),
  //   ];
  // }
  return holdingsCalls;
};

export const holdingsFilterTypeEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      TRADE_CONSTANTS.CHANGE_HOLDINGS_FILTER_TYPE,
      TRADE_CONSTANTS.CHANGE_HOLDINGS_CONTRACT_TYPE
    ),
    filter(() => isAuthenticated(state$.value.user) && !isTransactionLogPage()),
    debounce(i => interval(200 * i)),
    distinctUntilChanged(),
    mergeMap(() =>
      from(selectedOrderHistory(state$.value)).pipe(
        map(pAction => pAction()),
        catchError(err => console.error('DEBUG:\n', err))
      )
    )
  );

export const zeroFeeOptionsOfferEpic = (action$, state$) => {
  return action$.pipe(
    ofType(TRADE.ORDER.SUCCESS),
    map(action => {
      try {
        const { payload } = action;
        const { product_id: productId, side } = payload;
        const product = getProductByIDHelper(state$.value, productId);

        const { contract_type: contractType } = product;
        const isOptionsProduct = isOptions(contractType);

        return isOptionsProduct && side === 'buy' ? [getAppliedPromos] : [];
      } catch (error) {
        return [];
      }
    }),
    mergeMap(postActions => {
      if (!postActions) {
        return EMPTY;
      }
      return of(...postActions).pipe(
        map(pAction => pAction()),
        catchError(err => console.error('DEBUG:\n', err))
      );
    })
  );
};

export default combineEpics(
  authTradeEpic,
  tradeEpic,
  holdingsFilterTypeEpic,
  zeroFeeOptionsOfferEpic
);
