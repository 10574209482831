/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import { OAUTH_PROVIDERS } from 'constants/enums';

interface BaseInputState extends ErrorInfo {
  value: string;
}

interface OAuthState extends ErrorInfo {
  provider: 'google' | 'apple' | 'email';
}

interface ErrorInfo {
  error?: boolean;
  errorMessage?: string | React.ReactNode;
  errorCode?: string;
}

interface ErrorContext {
  count?: number;
  max_attempts?: number;
  time?: number;
}

export type PasswordConditionsType = {
  minCharacters: boolean;
  uppercaseLetter: boolean;
  lowercaseLetter: boolean;
  specialCharacter: boolean;
  number: boolean;
};

enum ActionKind {
  // Login
  SetPassword = 'setPassword',
  SetEmail = 'setEmail',
  LoginSuccess = 'loginSuccess',
  LoginFailure = 'loginFailure',
  ResetLogin = 'resetLogin',

  // LinkAccount
  ResetLinkAccount = 'resetLinkAccount',

  // Login Verification
  Login_Pre2FA_Success = 'login_Pre2FA_Success',
  Login_Pre2FA_Failure = 'login_Pre2FA_Failure',
  ResetVerification = 'setResetVerification',

  // Signup
  SetEmailDomainCorrection = 'setEmailDomainCorrection',
  SetReferralCode = 'setReferralCode',
  SignupPre2FASuccess = 'signupPre2FASuccess',
  SetCountry = 'setCountry',
  RegisterSuccess = 'registerSuccess',
  RegisterFailure = 'registerFailure',
  ReferralCodeIsValid = 'referralCodeIsValid',
  ReferralCodeIsInValid = 'referralCodeIsInValid',
  ReferralValidationIsLoading = 'referralValidationIsLoading',
  ResetSignup = 'resetSignup',

  // Signup Verification
  SignupSuccess = 'signupSuccess',
  SignupFailure = 'signupFailure',

  // Email Verification
  SetEmailCode = 'setEmailCode',
  ResendEmailOTPSuccess = 'resendEmailOTPSuccess',
  ResendMobileOTPSuccess = 'resendMobileOTPSuccess',
  SendEmailOTPFromLinkSuccess = 'sendEmailOTPFromLinkSuccess',
  SendMobileOTPFromLinkSuccess = 'sendMobileOTPFromLinkSuccess',
  ResendEmailOTPFailure = 'resendEmailOTPFailure',
  ResendMobileOTPFailure = 'resendMobileOTPFailure',
  EnableResend = 'enableResend',
  ShowLinkLoader = 'showLinkLoader',

  // Mfa Verification
  SetMfaCode = 'setMfaCode',

  // Reset Password
  ResetPasswordSuccess = 'resetPasswordSuccess',
  ResetPasswordFailure = 'resetPasswordFailure',

  // common
  RecaptchaError = 'recaptchaError',
  SetDefaultError = 'setDefaultError',
  SetLoader = 'setLoader',
  BeginPasswordValidation = 'beginPasswordValidation',
  SubmitClick = 'submitClick',
  ApiBlocked = 'apiBlocked',
  ResetResendOtpError = 'ResetResendOtpError',

  // OAuth
  OAuthSignUpInit = 'OAuthSignUpInit',
  OAuthSignUpError = 'OAuthSignUpError',
  OAuthLoginInit = 'OAuthLoginInit',
  OAuthLoginError = 'OAuthLoginError',
  SetOAuthBtnLoader = 'setOAuthBtnLoader',
  setComplianceModal = 'setComplianceModal',
}

type OAuthProvider = OAUTH_PROVIDERS.GOOGLE | OAUTH_PROVIDERS.APPLE | 'email';

type COMMON_ACTION_TYPE =
  | { type: ActionKind.SetLoader | ActionKind.SetOAuthBtnLoader; payload: boolean }
  | {
      type: ActionKind.SetDefaultError;
      payload: string;
    }
  | {
      type: ActionKind.RecaptchaError;
      payload?: null;
    };

export type {
  ErrorInfo,
  BaseInputState,
  ErrorContext,
  COMMON_ACTION_TYPE,
  OAuthState,
  OAuthProvider,
};
export { ActionKind };
