/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import BASKET_ORDERS, {
  ADD_PRODUCT_TO_BASKET,
  CHANGE_BASKET_ORDERS_SCREEN,
  DELETE_ALL_ORDERS_FROM_BASKET,
  DELETE_BASKET_RESULT,
  DELETE_PRODUCT_FROM_BASKET,
  EDIT_ORDER_FROM_BASKET,
  CHANGE_BASKET_ORDERS_CONTRACT_TYPE,
  TOGGLE_BASKET_ORDERS_VIEW,
  CHANGE_BASKET_ORDERS_UNDERLYING_ASSET,
  TOGGLE_BASKET_ORDER_MOBILE_ASSET_DROPDOWN,
  SET_BASKET_ORDER_INPUT_ERROR,
  RESET_BASKET_ORDER_INPUT_ERROR,
} from 'actionTypes/basketorders';
import TRADE, { TRADE_CONSTANTS } from 'actionTypes/trade';
import USER, { SWITCH_TO_MAIN_ACCOUNT } from 'actionTypes/user';
import createReducer from 'helpers/createReducer';
import IBasketOrderInitialState, {
  OrderInputFieldErrorObject,
  OrderResponse,
  OrdersObject,
} from 'types/basketOrders';
import { Product } from 'types/IProducts';

interface IAction {
  type: string;
  payload: {
    product_id: number;
    side: 'buy' | 'sell';
    symbol: string;
    asset: string;
    limit_price?: number;
    size?: number;
    order_type?: string;
    time_in_force: string;
    contract_type: string;
  };
}

interface IBasketSuccessResponse {
  type: string;
  result: {
    result?: Array<{ result: OrderResponse; success: boolean }>;
    success?: boolean;
  };
}

interface BasketOrderState {
  isBasketOrdersView: boolean;
  currentScreen: string;
  orders: OrdersObject;
  isLoading: boolean;
  basketResult: OrdersObject;
  activeContractType: string;
  activeUnderlyingAsset: string;
  isMobileAssetDropdownActive: boolean;
  orderInputFieldsError: OrderInputFieldErrorObject | null;
}

const initialState: IBasketOrderInitialState = {
  isBasketOrdersView: false,
  currentScreen: 'default',
  orders: {},
  isLoading: false,
  basketResult: {},
  activeContractType: 'Options',
  activeUnderlyingAsset: '',
  isMobileAssetDropdownActive: false,
  orderInputFieldsError: null,
};

export default createReducer(initialState, {
  [TOGGLE_BASKET_ORDERS_VIEW]: (state: BasketOrderState, action: { payload: any }) => ({
    ...state,
    isBasketOrdersView: action.payload,
  }),
  [CHANGE_BASKET_ORDERS_SCREEN]: (state: BasketOrderState, action: { payload: any }) => ({
    ...state,
    currentScreen: action.payload,
  }),
  [CHANGE_BASKET_ORDERS_CONTRACT_TYPE]: (
    state: BasketOrderState,
    action: { payload: string }
  ) => ({
    ...state,
    activeContractType: action.payload,
  }),
  [CHANGE_BASKET_ORDERS_UNDERLYING_ASSET]: (
    state: BasketOrderState,
    action: { payload: any }
  ) => ({
    ...state,
    activeUnderlyingAsset: action.payload,
  }),
  [TOGGLE_BASKET_ORDER_MOBILE_ASSET_DROPDOWN]: (
    state: BasketOrderState,
    action: { payload: any }
  ) => ({
    ...state,
    isMobileAssetDropdownActive: action.payload,
  }),
  [ADD_PRODUCT_TO_BASKET]: (state: { orders: BasketOrderState }, action: IAction) => {
    return {
      ...state,
      orders: {
        ...state.orders,
        [action.payload.product_id]: {
          ...action.payload,
          time_in_force: 'gtc',
        }, // time in force will always be gtc for basket orders
      },
    };
  },
  [DELETE_PRODUCT_FROM_BASKET]: (state: { orders: any }, action: IAction) => {
    const orders = { ...state.orders };
    delete orders[action.payload.product_id];
    return {
      ...state,
      orders,
    };
  },
  [EDIT_ORDER_FROM_BASKET]: (state: { orders: any }, action: IAction) => {
    const orders = { ...state.orders };
    orders[action.payload.product_id] = {
      ...action.payload,
      time_in_force: 'gtc',
    };
    return {
      ...state,
      orders,
    };
  },
  [BASKET_ORDERS.PLACE_ORDER.LOAD]: (state: BasketOrderState) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [BASKET_ORDERS.PLACE_ORDER.FAIL]: (state: BasketOrderState) => {
    return {
      ...state,
      isLoading: false,
    };
  },
  [BASKET_ORDERS.PLACE_ORDER.SUCCESS]: (
    state: BasketOrderState,
    action: IBasketSuccessResponse
  ) => {
    const response = action?.result?.result.reduce(
      (acc, orderResponse) => {
        const { product_id } = orderResponse.result;
        return {
          ...acc,
          basketSuccessResponse: {
            ...acc.basketSuccessResponse,
            [product_id]: {
              result: orderResponse.result,
              success: orderResponse.success,
            },
          },
          // orders: {
          //   ...acc.orders,
          //   [product_id]: {
          //     ...acc.orders[product_id],
          //     side,
          //     product_id,
          //     limit_price: 0,
          //     size: 0,
          //     symbol: product_symbol,
          //     order_type,
          //     time_in_force,
          //   },
          // },
        };
      },
      { basketSuccessResponse: {}, orders: {} }
    );

    return {
      ...state,
      // orders: response.orders,
      basketResult: response.basketSuccessResponse,
      isLoading: false,
    };
  },
  [DELETE_BASKET_RESULT]: (state: BasketOrderState) => {
    return {
      ...state,
      basketResult: [],
    };
  },
  [TRADE.UPDATE_PORTFOLIO_MARGIN_NOTIFICATION.SUCCESS]: (state: BasketOrderState) => {
    return {
      ...state,
      orders: {},
      basketResult: [],
    };
  },
  [TRADE.UPDATE_PORTFOLIO_MARGIN.SUCCESS]: (state: BasketOrderState) => {
    return {
      ...state,
      orders: {},
      basketResult: [],
    };
  },
  [TRADE.UPDATE_MARGIN_MODE.SUCCESS]: (state: BasketOrderState) => {
    return {
      ...state,
      orders: {},
      basketResult: [],
    };
  },
  [TRADE_CONSTANTS.UPDATE_PRODUCT]: (
    state: { orders: { [x: string]: any } },
    action: { payload: Product }
  ) => {
    const product: Product = action.payload;
    const productId = product?.id;
    const isExpired = product?.state === 'expired';
    const newOrders = { ...state.orders };
    if (isExpired && state.orders[productId]) {
      delete newOrders[productId];
    }
    return {
      ...state,
      orders: { ...newOrders },
    };
  },
  [USER.LOGOUT.SUCCESS]: (state: BasketOrderState) => {
    return {
      ...state,
      isBasketOrdersView: false,
      currentScreen: 'default',
      orders: {},
      isLoading: false,
      basketResult: {},
    };
  },
  [DELETE_ALL_ORDERS_FROM_BASKET]: (state: BasketOrderState) => ({
    ...state,
    orders: {},
  }),
  [SWITCH_TO_MAIN_ACCOUNT]: (state: BasketOrderState) => ({
    ...state,
    orders: {},
  }),
  [USER.GET_ACCOUNT_TOKEN.SUCCESS]: (state: BasketOrderState) => ({
    ...state,
    orders: {},
  }),
  [SET_BASKET_ORDER_INPUT_ERROR]: (state: BasketOrderState, action: IAction) => {
    let existingOrderError = {};
    if (state?.orderInputFieldsError) {
      existingOrderError = state.orderInputFieldsError;
    }
    return {
      ...state,
      orderInputFieldsError: { ...existingOrderError, ...action.payload },
    };
  },
  [RESET_BASKET_ORDER_INPUT_ERROR]: (state: BasketOrderState) => {
    return {
      ...state,
      orderInputFieldsError: null,
    };
  },
});
