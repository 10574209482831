import keyMirror from '../helpers/keymirror';

export default keyMirror({
  OPEN_STOP_ORDERS: null,
  WITHDRAWAL_DATA: null,
  PENDING_WITHDRAWAL_DATA: null,
  CLOSE_POSITION: null,
  PRODUCTS: null,
  SPOT_INDICES: null,
  FUNDS: null,
  ORDER: null,
  ESTIMATE_MARGIN: null,
  ORDER_CANCEL: null,
  POSITIONS: null,
  ASSETS: null,
  DEPOSIT_ASSETS: null,
  UNIVERSAL_SEARCH: null,
  OPEN_ORDERS: null,
  ORDERS_HISTORY: null,
  FILLS: null,
  L2ORDERBOOK: null,
  TRANSACTIONS: null,
  CANCEL_WITHDRAWAL: null,
  SPARKLINES: null,
  BRACKET_ORDER: null,
  EDIT_BRACKET_ORDER: null,
  CANCEL_BRACKET_ORDER: null,
  CANCEL_ALL_ORDER: null,
  POSITION_AUTO_TOPUP: null,
  TRADE_PREFERENCES: null,
  UPDATE_TRADE_PREFERENCE: null,
  UPDATE_PORTFOLIO_MARGIN: null,
  UPDATE_MARGIN_MODE: null,
  UPDATE_PORTFOLIO_MARGIN_NOTIFICATION: null, // Added a different actin because we need the bottom notifications only in the specific places
  CHART_HISTORY: null,
  ASSET_HISTORY: null,
  TICKER: null,
  ALL_TICKERS: null,
  WITHDRAWAL_LIMITS: null,
  WITHDRAWAL_TRADING_CREDITS: null,
  WITHDRAWAL_TRADING_CREDITS_FORFEIT: null,
  WALLET_BALANCE_TOP_UP: null,
  GET_WITHDRAWAL_OTP: null,
  EXPIRED_PRODUCTS: null,
  SHARE_IMAGE: null,
  UPDATE_BRACKET_ORDER_FROM_OPEN_ORDER: null,
  POSITION_BRACKET_ORDER_MESSAGE: null,
  POSITION_BRACKET_ORDER_ERROR_MESSAGE: null,
  PLACE_BRACKET_ORDER_POSITION: null,
  EDIT_BRACKET_ORDER_POSITION: null,
  CANCEL_BRACKET_ORDER_POSITION: null,
  SHARE_CARD: null,
  CLOSE_ALL_POSITION: null,
  GET_STATISTICS: null,
  CLOSE_POSITION_BASKET: null,
  GET_FIAT_CURRENCIES: null,
  GET_FIAT_DEPOSIT_ASSETS: null,
  GET_CRYPTO_FIAT_CONVERSION: null,
  GET_CLOSE_POSITION_SHARE_CARD: null,
  GET_ALPYNE_SIGNATURE: null,
  GET_ALPYNE_USDT_QUOTE: null,
  GET_OPTIONS_RECENT_TRADES: null,
  GET_TOP_OPTIONS_MARKETS_DATA: null,
  GET_TOP_FUTURES_MARKETS_DATA: null,
  GET_TOP_OPTIONS_COMBOS_MARKETS_DATA: null,
  RECENT_PRODUCT_SEARCHES: null,
  SAVE_PRODUCT_SEARCHES: null,
  GET_PRODUCT: null,
  UPDATE_PRICE_ALERT_PREFERENCE: null,
  ENABLE_FUTURES_SCALPING: null,
  GET_OPTIONS_INFO: null,
  GET_OPTIONS_CHAIN: null,
});

export const TRADE_CONSTANTS = {
  ASSET_SELECTED: 'ASSET_SELECTED',
  PRODUCT_SELECTED: 'PRODUCT_SELECTED',
  LOAD_TRADE_PRODUCTS: 'LOAD_TRADE_PRODUCTS',
  L2UPDATE_PRICE: 'L2UPDATE_PRICE',
  UPDATE_ORDERBOOK: 'UPDATE_ORDERBOOK',
  RECENT_TRADES: 'RECENT_TRADES',
  UPDATE_RECENT_TRADES: 'UPDATE_RECENT_TRADES',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  CHANGE_CONTRACT_TYPE: 'CHANGE_CONTRACT_TYPE',
  UPDATE_POSITIONS: 'UPDATE_POSITIONS',
  UPDATE_OPEN_ORDERS: 'UPDATE_OPEN_ORDERS',
  UPDATE_STOP_ORDERS: 'UPDATE_STOP_ORDERS',
  OPEN_STOP_ORDERS: 'OPEN_STOP_ORDERS',
  OPEN_ORDERS: 'OPEN_ORDERS',
  OPEN_POSITIONS: 'OPEN_POSITIONS',
  CHANGE_HOLDINGS_CONTRACT_TYPE: 'CHANGE_HOLDINGS_CONTRACT_TYPE',
  CHANGE_MOBILE_CONTRACT_TYPE: 'CHANGE_MOBILE_CONTRACT_TYPE',
  UPDATE_BRACKET_ORDER: 'UPDATE_BRACKET_ORDER',
  DELETE_BRACKET_ORDER: 'DELETE_BRACKET_ORDER',
  CHANGE_HOLDINGS_FILTER_TYPE: 'CHANGE_HOLDINGS_FILTER_TYPE',
  RESET_ASSET_HISTORY: 'RESET_ASSET_HISTORY',
  SHOW_WITHDRAWAL_HISTORY: 'SHOW_WITHDRAWAL_HISTORY',
  SHOW_DEPOSIT_HISTORY: 'SHOW_DEPOSIT_HISTORY',
  ACTIVATE_REST_MODE: 'ACTIVATE_REST_MODE',
  PLACEORDER_CLICKED: 'PLACEORDER_CLICKED',
  ORDERS_API_BLOCKED: 'ORDERS_API_BLOCKED',
  CLOSE_POSITION_API_BLOCKED: 'CLOSE_POSITION_API_BLOCKED',
  CLOSE_POSITION_CLICKED: 'CLOSE_POSITION_CLICKED',
  INITIATE_TICKER: 'INITIATE_TICKER',
  POLL_OPTIONS_TICKER: 'POLL_OPTIONS_TICKER',
  KILL_TICKER_TIMER: 'KILL_TICKER_TIMER',
  PREVIOUS_PRODUCT_SELECTED: 'PREVIOUS_PRODUCT_SELECTED',
  SHOW_PORTFOLIO_HIGHLIGHTER: 'SHOW_PORTFOLIO_HIGHLIGHTER',
  POLL_ALL_OPEN_POSITION_TICKER: 'POLL_ALL_OPEN_POSITION_TICKER',
  KILL_ALL_OPEN_POSITION_TICKER_TIMER: 'KILL_ALL_OPEN_POSITION_TICKER_TIMER',
  REMOVE_ACTIVE_CLOSE_POSITION_SHARE_CARD: 'REMOVE_ACTIVE_CLOSE_POSITION_SHARE_CARD',
  SET_ACTIVE_CLOSE_POSITION_SHARE_CARD: 'SET_ACTIVE_CLOSE_POSITION_SHARE_CARD',
  POLL_PRODUCT_TICKER: 'POLL_PRODUCT_TICKER',
  KILL_PRODUCT_TICKER_TIMER: 'KILL_PRODUCT_TICKER_TIMER',
  RESET_FIAT_DEPOSIT_ASSETS: 'RESET_FIAT_DEPOSIT_ASSETS',
  POLL_TOP_GAINERS_LOSERS: 'POLL_TOP_GAINERS_LOSERS',
  KILL_TOP_GAINERS_LOSERS_TIMER: 'KILL_TOP_GAINERS_LOSERS_TIMER',
  RESET_SCALPER_ERROR_MESSAGE: 'RESET_SCALPER_ERROR_MESSAGE',
  REFRESH_OPTIONS_INFO: 'REFRESH_OPTIONS_INFO',
};
