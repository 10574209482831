import React, { SVGProps } from 'react';

const DeltaLogoMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={27}
    height={27}
    fill="none"
    viewBox="0 0 27 27"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    data-palette="DeltaLogoMobile">
    <path
      d="m9.032 8.86 8.925 4.43 8.925-4.43L9.032 0v8.86Z"
      fill="url(#deltaLogoGradientA)"
    />
    <path
      d="M9.032 17.72v8.86l17.85-8.86-8.925-4.43-8.925 4.43Z"
      fill="url(#deltaLogoGradientB)"
    />
    <path d="M26.882 17.72V8.86l-8.925 4.43 8.925 4.43Z" fill="#00B5EB" />
    <path d="M9.032 8.86v8.86L.107 13.29l8.925-4.43Z" fill="#06F922" />
    <defs>
      <linearGradient
        id="deltaLogoGradientA"
        x1={29.337}
        y1={7.587}
        x2={19.082}
        y2={-1.303}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#1C53A4" />
        <stop offset={1} stopColor="#00B5EB" />
      </linearGradient>
      <linearGradient
        id="deltaLogoGradientB"
        x1={20.7}
        y1={9.557}
        x2={10.971}
        y2={18.603}
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#1C53A4" />
        <stop offset={1} stopColor="#00B5EB" />
      </linearGradient>
    </defs>
  </svg>
);

export default DeltaLogoMobile;
