/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["^draft"] }] */
import { produce } from 'immer';

import ACCOUNT_ACTION_TYPES, {
  DepositsActionTypeIndia,
  FiatBankDetailsActionTypeIndia,
} from 'actionTypes/account';
import USER from 'actionTypes/user';
import { API_STATUS, TRANSACTION_PARTNER_NAMES } from 'constants/enums';

const { VAN, DE_INTERNAL } = TRANSACTION_PARTNER_NAMES;

const INITIAL_STATE = {
  apiStatus: API_STATUS.INITIAL,
  bankList: [],
  transferModes: [],
  enabledDepositPartners: [VAN],
  activeDepositPartner: VAN,
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ACCOUNT_ACTION_TYPES[FiatBankDetailsActionTypeIndia.GET_FIAT_BANK_DETAILS]
      .LOAD: {
      draft.apiStatus = API_STATUS.LOADING;
      break;
    }

    case ACCOUNT_ACTION_TYPES[FiatBankDetailsActionTypeIndia.GET_FIAT_BANK_DETAILS]
      .FAIL: {
      draft.apiStatus = API_STATUS.ERROR;
      break;
    }

    case ACCOUNT_ACTION_TYPES[FiatBankDetailsActionTypeIndia.GET_FIAT_BANK_DETAILS]
      .SUCCESS: {
      draft.apiStatus = API_STATUS.SUCCESS;

      const data = action.result.result;

      draft.transferModes = data.transfer_mode;

      draft.bankList = data.bank_details.map(account => ({
        id: account.user_bank_detail_id,
        label: account.customized_bank_name,
        bankName: account.bank_name,
        accountNumber: account.account_number,
      }));
      break;
    }

    case USER.GET_KYC_STATUS.SUCCESS: {
      const { permissions } = action.result.result;
      const isDeInternalFlowEnabledForUser = permissions?.de_internal_flow;

      if (isDeInternalFlowEnabledForUser) {
        draft.enabledDepositPartners = [DE_INTERNAL, VAN];
      }

      draft.activeDepositPartner = isDeInternalFlowEnabledForUser ? DE_INTERNAL : VAN;
      break;
    }

    case ACCOUNT_ACTION_TYPES[DepositsActionTypeIndia.SET_ACTIVE_DEPOSIT_PARTNER]: {
      draft.activeDepositPartner = action.payload;
      break;
    }

    default:
      break;
  }
}, INITIAL_STATE);

export default reducer;
