/* eslint-disable import/no-unresolved */
import { createSelector } from 'reselect';

import { prop } from 'helpers/ramda';
import { selectedProductSelector } from 'selectors/tradeSelectors';
import { IOrdersObject } from 'types/basketOrders';
import { ContractType, Product } from 'types/IProducts';
import IState from 'types/Istore';

export const basketOrderSelector = (state: IState) => state.basketOrders;

export const basketOrderSwitchSelector = createSelector(
  [basketOrderSelector],
  prop('isBasketOrdersView')
);

export const basketOrderCurrentScreenSelector = createSelector(
  [basketOrderSelector],
  prop('currentScreen')
);

export const basketOrderOrdersSelector = createSelector(
  [basketOrderSelector],
  prop('orders')
);

export const showBasketLoadingSelector = createSelector(
  [basketOrderSelector],
  prop('isLoading')
);

export const basketSuccessResponseSelector = createSelector(
  [basketOrderSelector],
  prop('basketResult')
);

export const optionsOrdersInBasketBySelectedUnderlying = createSelector(
  [basketOrderOrdersSelector, selectedProductSelector],
  (ordersInBasket: IOrdersObject, selectedProduct: Product) => {
    const orders = Object.values(ordersInBasket).filter(
      order =>
        (order.contract_type === ContractType.CallOptions ||
          order.contract_type === ContractType.PutOptions) &&
        order.asset === selectedProduct?.underlying_asset?.symbol
    );
    return orders;
  }
);

export const futureOrdersInBasketBySelectedUnderlying = createSelector(
  [basketOrderOrdersSelector, selectedProductSelector],
  (ordersInBasket: IOrdersObject, selectedProduct: Product) => {
    const orders = Object.values(ordersInBasket).filter(
      order =>
        (order.contract_type === ContractType.PerpetualFutures ||
          order.contract_type === ContractType.Futures) &&
        order.asset === selectedProduct?.underlying_asset?.symbol
    );
    return orders;
  }
);

export const basketActiveContractTypeSelector = createSelector(
  [basketOrderSelector],
  prop('activeContractType')
);

export const basketActiveUnderlyingAssetSelector = createSelector(
  [basketOrderSelector],
  prop('activeUnderlyingAsset')
);

export const isMobileAssetDropdownOpenSelector = createSelector(
  [basketOrderSelector],
  prop('isMobileAssetDropdownActive')
);

export const basketSelectedUnderlyingAssetSelector = createSelector(
  [
    basketOrderSwitchSelector,
    selectedProductSelector,
    basketActiveUnderlyingAssetSelector,
  ],
  (isBasketOrderView, selectedProduct, basketOrderActiveAssetSymbol) => {
    const isSelectedProductOptionsChain =
      selectedProduct &&
      (selectedProduct.contract_type === 'call_options' ||
        selectedProduct.contract_type === 'put_options');

    let defaultSelectedAsset;
    if (isBasketOrderView) {
      defaultSelectedAsset = basketOrderActiveAssetSymbol;
    } else if (isSelectedProductOptionsChain) {
      defaultSelectedAsset = selectedProduct.underlying_asset?.symbol;
    } else {
      defaultSelectedAsset = 'BTC'; // always will be BTC
    }
    return defaultSelectedAsset;
  }
);

export const basketOrderInputFieldsErrorSelector = createSelector(
  [basketOrderSelector],
  prop('orderInputFieldsError')
);
