import React, { SVGProps } from 'react';

const PlayStoreIcon = ({
  height = 25,
  width = 24,
  className,
  viewBox,
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox={viewBox}
    data-palette="PlayStoreIcon">
    <path
      d="M1.374 1.347C1.093 1.634.93 2.08.93 2.659v20.635c0 .579.163 1.026.444 1.313l.07.063L13.32 13.11v-.272L1.444 1.279l-.07.068Z"
      fill="url(#PlayStoreIconA)"
    />
    <path
      d="M17.275 16.966 13.32 13.11v-.273l3.96-3.855.088.05 4.69 2.598c1.338.737 1.338 1.95 0 2.692l-4.69 2.593-.093.05Z"
      fill="url(#PlayStoreIconB)"
    />
    <path
      d="m17.37 16.916-4.048-3.941L1.375 24.607c.444.455 1.17.51 1.994.055l14.001-7.746Z"
      fill="url(#PlayStoreIconC)"
    />
    <path
      d="M17.37 9.033 3.37 1.287c-.825-.45-1.55-.395-1.994.06l11.947 11.627 4.048-3.94Z"
      fill="url(#PlayStoreIconD)"
    />
    <defs>
      <linearGradient
        id="PlayStoreIconA"
        x1={12.267}
        y1={23.509}
        x2={-3.387}
        y2={7.43}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0FF" />
        <stop offset={0.007} stopColor="#00A1FF" />
        <stop offset={0.26} stopColor="#00BEFF" />
        <stop offset={0.512} stopColor="#00D2FF" />
        <stop offset={0.76} stopColor="#00DFFF" />
        <stop offset={1} stopColor="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="PlayStoreIconB"
        x1={23.8}
        y1={12.973}
        x2={0.609}
        y2={12.973}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE000" />
        <stop offset={0.409} stopColor="#FFBD00" />
        <stop offset={0.775} stopColor="orange" />
        <stop offset={1} stopColor="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="PlayStoreIconC"
        x1={15.169}
        y1={10.832}
        x2={-6.059}
        y2={-10.973}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3A44" />
        <stop offset={1} stopColor="#C31162" />
      </linearGradient>
      <linearGradient
        id="PlayStoreIconD"
        x1={-1.632}
        y1={31.472}
        x2={7.847}
        y2={21.735}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32A071" />
        <stop offset={0.069} stopColor="#2DA771" />
        <stop offset={0.476} stopColor="#15CF74" />
        <stop offset={0.801} stopColor="#06E775" />
        <stop offset={1} stopColor="#00F076" />
      </linearGradient>
    </defs>
  </svg>
);

export default PlayStoreIcon;
